<template>
  <main class="main">
    <Header
      :is-active-toggle-btn="mobileMenu"
      @toggle-menu="toggleMobileMenu"
    />
    <transition name="slide">
      <MobileMenu
        v-if="mobileMenu"
        :is-open="mobileMenu"
        @close="toggleMobileMenu"
      />
    </transition>
    <Content />
    <Footer />

    <Notification
      v-if="notification"
      :type="notification.type"
      :title="notification.title"
      :text="notification.text"
    />
  </main>
</template>

<script>
import Header from '@/components/Layout/Header/Header.vue';
import MobileMenu from '@/components/Layout/Header/MobileMenu.vue';
import Content from '@/components/Layout/Content/Content.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';

import Notification from '@/elements/Notification/Notification.vue';
export default {
  components: {
    Header,
    MobileMenu,
    Content,
    Footer,
    Notification,
  },
  data() {
    return {
      mobileMenu: false,
    };
  },
  computed: {
    notification() {
      return this.$store.getters.getNotification;
    },
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
  },
};
</script>
<style lang="sass" scoped>
.main
  min-height: 100vh
  display: flex
  flex-direction: column

.slide-enter-active
   transition-duration: 0.2s
   transition-timing-function: ease-in

.slide-leave-active
   transition-duration: 0.2s
   transition-timing-function: ease-in

.slide-enter-to, .slide-leave
  opacity: 1
  // transform: translateX(0%)

.slide-enter, .slide-leave-to
  opacity: 0
  // transform: translateX(-100%)
</style>
