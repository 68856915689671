<template>
  <header class="header">
    <div :class="container">
      <div class="header__inner">
        <div class="header__left">
          <div class="header__logo">
            <AppLogo @click.native.prevent="logoClickHandler" />
          </div>
        </div>
        <div v-if="isLandingPage" class="header__center nav">
          <a href="#how-work" class="nav__link" @click="goToMainPage">
            {{ $t('header.links.item_1') }}
          </a>
          <a href="#how-get" class="nav__link" @click="goToMainPage">
            {{ $t('header.links.item_2') }}
          </a>
          <a href="#usefull-info" class="nav__link" @click="goToMainPage">
            {{ $t('header.links.item_3') }}
          </a>
          <a href="#contacts" class="nav__link" @click="goToMainPage">
            {{ $t('header.links.item_4') }}
          </a>
        </div>
        <div class="header__right">
          <!-- <SelectLangField class="header__lang" /> -->
          <UserProfile v-if="isAuthUser" />
          <ButtonBase
            v-if="isLandingPage && !isAuthUser"
            size="m"
            color="blue"
            class="header__registration-btn"
            @click.native="goToRegistration"
          >
            {{ $t('header.signup_btn') }}
          </ButtonBase>
          <div
            class="header__menu-btn hamburger"
            :class="{ opened: isOpenedMenu || isActiveToggleBtn }"
            @click="toggleMenu"
          >
            <span class="bar bar-1"></span>
            <span class="bar bar-2"></span>
            <span class="bar bar-3"></span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { isLSHasItem, removeFromLS } from '@/library/helpers';
import AppLogo from '@/components/Logo/AppLogo.vue';
import UserProfile from '@/components/UserProfile/UserProfile.vue';

// import SelectLangField from '@/elements/Inputs/SelectLangField.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

export default {
  components: {
    AppLogo,
    UserProfile,
    // SelectLangField,
    ButtonBase,
  },
  props: {
    isActiveToggleBtn: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isOpenedMenu: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthUser']),
    container() {
      return this.isLandingPage ? 'container--main-page' : 'container';
    },
    isClickableLogo() {
      return this.$route.name !== 'MainPage';
    },
    isLandingPage() {
      return (
        this.$route.path === '/' ||
        this.$route.path === '/privacy' ||
        this.$route.path === '/terms'
      );
    },
  },
  watch: {
    isActiveToggleBtn(newVal) {
      if (!newVal) {
        this.isOpenedMenu = false;
      }
    },
  },
  // mounted() {
  //   window.addEventListener('scroll', this.stickyHeaderHandler);
  // },
  // destroyed() {
  //   window.removeEventListener('scroll', this.stickyHeaderHandler);
  // },
  methods: {
    // stickyHeaderHandler() {
    //   const body = document.body;
    //   const currentScroll = window.pageYOffset;
    //   let lastScroll = 0;

    //   if (currentScroll <= 0) {
    //     body.classList.remove('scroll-up');
    //     // return;
    //   }

    //   if (
    //     currentScroll > lastScroll &&
    //     !body.classList.contains('scroll-down')
    //   ) {
    //     // down
    //     console.log('down');
    //     body.classList.remove('scroll-up');
    //     body.classList.add('scroll-down');
    //   } else if (
    //     currentScroll < lastScroll &&
    //     body.classList.contains('scroll-down')
    //   ) {
    //     // up
    //     console.log('up');
    //     body.classList.remove('scroll-down');
    //     body.classList.add('scroll-up');
    //   }
    //   lastScroll = currentScroll;
    // },
    logoClickHandler() {
      if (this.isClickableLogo) {
        this.clearLS();
        this.$router.push({ name: 'MainPage' });
      }

      return;
    },
    toggleMenu() {
      this.isOpenedMenu = !this.isOpenedMenu;
      this.$emit('toggle-menu');
    },
    clearLS() {
      if (isLSHasItem('new-ticket')) {
        removeFromLS('new-ticket');
      }

      if (isLSHasItem('order_id')) {
        removeFromLS('order_id');
      }

      return;
    },
    goToRegistration() {
      this.$router.push({ name: 'Auth' });
    },
    goToMainPage() {
      if (this.$route.path !== '/') {
        this.$router.push({ name: 'MainPage' });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.header
  padding: 18px 0 14px 0
  border-bottom: 1px solid var(--primary-color-light-grey-2)

  @media screen and (max-width: 1199px)
    padding: 15px 0

  .user-profile
    @media screen and (max-width: 991px)
      display: none

.header.fixed
  position: fixed
  top: 0
  left: 0
  width: 100%
  background-color: var(--primary-color-white)
  z-index: 99

  .user-profile
    @media screen and (max-width: 991px)
      display: none

.header__inner
  display: flex
  align-items: center
  justify-content: space-between

.header__right
  display: flex
  align-items: center
  gap: 20px

.header__lang
  @media screen and (max-width: 991px)
    display: none

.header__registration-btn.primary-btn--medium
  height: 48px
  box-shadow: 0px 4px 8px rgba(62, 85, 236, 0.25)

  @media screen and (max-width: 600px)
    display: none

.nav
  display: flex
  justify-content: space-between
  gap: 32px
  font-size: 18px

  @media screen and (max-width: 1199px)
    display: none

.nav__link
  border-bottom: 1px solid transparent
  color: var(--primary-color-dark-blue)

.nav__link:hover
  border-bottom: 1px solid var(--btn-color-blue-hover)
  color: var(--btn-color-blue-hover)
  cursor: pointer

.header__menu-btn
  display: none

  @media screen and (max-width: 991px)
    display: flex

.hamburger
  width: 48px
  height: 48px
  border: 1px solid var(--primary-color-light-grey-7)
  border-radius: var(--primary-border-radius)
  align-items: center
  justify-content: center
  flex-direction: column
  cursor: pointer

  @media screen and (max-width: 600px)
    width: 36px
    height: 36px

.bar
  display: block
  width: 26px
  height: 3px
  background-color: var(--primary-color-dark-grey-1)
  border-radius: 2px
  -webkit-transition: all var(--transition-speed) ease-in-out
  transition: all var(--transition-speed) ease-in-out

  @media screen and (max-width: 600px)
    width: 20px

.bar+.bar
  margin-top: 5px

.hamburger.opened
  .bar-1
    -webkit-transform: translate(0px,8px) rotate(45deg)
    transform: translate(0px,8px) rotate(45deg)

  .bar-2
    opacity: 0

  .bar-3
    -webkit-transform: translate(0px, -8px) rotate(-45deg)
    transform: translate(0px, -8px) rotate(-45deg)
</style>
