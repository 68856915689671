<template>
  <div class="content">
    <div v-if="!isLandingPage" class="container">
      <router-view />
    </div>
    <router-view v-else />
  </div>
</template>
<script>
export default {
  computed: {
    isLandingPage() {
      return (
        this.$route.path === '/' ||
        this.$route.path === '/terms' ||
        this.$route.path === '/privacy'
      );
    },
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.content
  background-color: var(--primary-color-light-grey-1)
  flex-grow: 1
  overflow: hidden
</style>
