<template>
  <div class="user-profile">
    <div class="user-profile__avatar-box">
      <svg-icon name="user-avatar"></svg-icon>
    </div>
    <div class="user-profile__info">
      <div class="user-profile__fullname">{{ user.fullname }}</div>
    </div>
    <div class="user-profile__btn-box">
      <ButtonLogout @click.native="openModal" />
    </div>
    <Popup :dialog.sync="modal">
      <div class="popup__logout-icon">
        <svg-icon name="logout"></svg-icon>
      </div>
      <Heading level="4" class="popup__title">Вихід із системи</Heading>
      <div class="popup__subtitle">
        Ви впевнені, що хочете вийти із системи?
      </div>
      <div class="popup__buttons-box">
        <ButtonBase
          color="white"
          size="md-bg"
          class="popup__btn"
          @click.native="closeModal"
        >
          <span>
            {{ $t('new_ticket_info.cancel') }}
          </span>
        </ButtonBase>
        <ButtonBase
          color="blue"
          size="md-bg"
          class="popup__btn"
          @click.native="logoutHandler"
        >
          <template v-if="!loadingLogoutBtn"> Підтвердити </template>
          <Loader v-else color="white" />
        </ButtonBase>
      </div>
    </Popup>
  </div>
</template>

<script>
import Heading from '@/elements/Heading/Heading.vue';

import { mapGetters } from 'vuex';
import ButtonLogout from '@/elements/Buttons/ButtonLogout.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

import Popup from '@/elements/Popup/Popup.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    Heading,
    ButtonLogout,
    ButtonBase,
    Popup,
    Loader,
  },
  data() {
    return {
      user: {
        avatar: '/images/user/avatar.svg',
        fullname: '',
      },
      modal: false,
      loadingLogoutBtn: false,
    };
  },

  computed: {
    ...mapGetters(['getUser']),
  },

  mounted() {
    const lastName = this.getUser?.last_name;
    const firstName = this.getUser?.first_name.slice(0, 1).toUpperCase();
    const secondName = this.getUser?.second_name
      ? ` ${this.getUser.second_name.slice(0, 1).toUpperCase()}.`
      : '';

    this.user.fullname = `${lastName} ${firstName}.${secondName}`;
  },

  methods: {
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    async logoutHandler() {
      this.closeModal();
      this.$emit('logout');
      this.loadingLogoutBtn = true;
      await this.$store.dispatch('userLogout');
      this.loadingLogoutBtn = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.user-profile
  display: flex
  align-items: center
  min-height: 48px

  @media screen and (max-width: 991px)
    width: 100%

  @media screen and (max-width: 600px)
    min-height: 42px

  @media screen and (max-width: 350px)
    min-height: 36px

.user-profile__avatar-box,
.user-profile__btn-box
  font-size: 0

.user-profile__btn-box
  @media screen and (max-width: 991px)
    margin-left: auto


.user-profile__avatar-box
  display: block
  margin-right: 12px
  @media screen and (max-width: 600px)
    margin-right: 7px

.user-profile__avatar-box svg
  @media screen and (max-width: 600px)
    height: 20px

.user-profile__info
  margin-right: 7px
  // @media screen and (max-width: 600px)
  //   margin-right: 8px

.user-profile__fullname
  font-weight: bold
  font-size: 16px
  line-height: 19px
  color: var(--primary-color-dark-blue)

  @media screen and (max-width: 350px)
    font-size: 14px
    line-height: 17px
</style>
