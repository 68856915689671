<template>
  <div class="header-mobile">
    <div class="header-mobile__overlay" @click="close"></div>
    <div class="header-mobile__content">
      <div v-if="isAuthUser" class="header-mobile__profile">
        <UserProfile @logout="close" />
      </div>
      <div v-if="isLandingPage" class="header-mobile__nav">
        <ul class="mobile-nav">
          <li class="mobile-nav__item">
            <a href="#how-work" class="mobile-nav__link" @click="close">
              {{ $t('header.links.item_1') }}
            </a>
          </li>
          <li class="mobile-nav__item">
            <a href="#how-get" class="mobile-nav__link" @click="close">
              {{ $t('header.links.item_2') }}
            </a>
          </li>
          <li class="mobile-nav__item">
            <a href="#usefull-info" class="mobile-nav__link" @click="close">
              {{ $t('header.links.item_3') }}
            </a>
          </li>
          <li class="mobile-nav__item">
            <a href="#contacts" class="mobile-nav__link" @click="close">
              {{ $t('header.links.item_4') }}
            </a>
          </li>
        </ul>
      </div>
      <!-- <div class="header-mobile__lang">
        <div
          v-for="item in locales"
          :key="item.value"
          class="header-mobile__lang-item"
          :class="{ selected: item.value === getLocale.value }"
          @click="selectLocale(item)"
        >
          {{ item.title }}
          <svg-icon
            v-if="item.value === getLocale.value"
            name="blue-check"
          ></svg-icon>
        </div>
      </div> -->
      <ButtonBase
        v-if="isLandingPage && !isAuthUser"
        size="m"
        color="blue"
        class="header-mobile__registration-btn"
        @click.native="goToRegistration"
      >
        {{ $t('header.signup_btn') }}
      </ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserProfile from '@/components/UserProfile/UserProfile.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

export default {
  components: {
    UserProfile,
    ButtonBase,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // locales: [
      //   {
      //     title: 'Укр',
      //     value: 'uk',
      //   },
      //   {
      //     title: 'Рос',
      //     value: 'ru',
      //   },
      //   {
      //     title: 'Анг',
      //     value: 'en',
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters([
      'isAuthUser',
      // 'getLocale'
    ]),
    isLandingPage() {
      return (
        this.$route.path === '/' ||
        this.$route.path === '/privacy' ||
        this.$route.path === '/terms'
      );
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizeHandler);
    document.body.classList.add('locked');
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
    document.body.classList.remove('locked');
  },
  methods: {
    resizeHandler() {
      if (document.documentElement.clientWidth > 991) {
        this.close();
      }
    },
    selectLocale(locale) {
      this.$store.commit('setLocale', locale);
    },
    close() {
      this.$emit('close');
    },
    goToRegistration() {
      this.close();
      this.$router.push({ name: 'Auth' });
    },
  },
};
</script>

<style lang="sass" scoped>
.header-mobile
  position: fixed
  top: 79px
  left: 0
  width: 100%
  height: calc(100vh - 79px)
  z-index: 10

  @media screen and (max-width: 600px)
    top: 67px
    height: calc(100vh - 67px)

.header-mobile__overlay
  background-color:  rgba(33, 33, 33, 0.4)
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1

.header-mobile__content
  position: absolute
  top: 0
  left: 0
  width: 100%
  max-height: 100%
  overflow-y: auto
  background-color: var(--primary-color-white)
  padding: 0 60px 62px 60px
  z-index: 2

  @media screen and (max-width: 600px)
    padding: 0 16px 55px 16px

.header-mobile__profile,
.mobile-nav__link,
.header-mobile__lang
  border-bottom: 1px solid var(--primary-color-light-grey-3)

.header-mobile__profile
  padding: 6px 0

.header-mobile__registration-btn
  height: 48px
  box-shadow: 0px 4px 8px rgba(62, 85, 236, 0.25)
  display: none

  @media screen and (max-width: 600px)
    display: flex
    width: 100%
    margin-top: 40px
    margin-left: auto
    margin-right: auto

.mobile-nav__link
  font-weight: 500
  color: var(--primary-color-dark-blue)
  display: block

.mobile-nav__link:hover
  color: var(--btn-color-blue)

.header-mobile__lang
  display: flex
  align-items: center

.header-mobile__lang-item
  flex: 0 0 33.3%
  font-weight: bold
  color: var(--primary-color-dark-blue)
  position: relative
  display: flex
  align-items: center
  gap: 16px
  cursor: pointer
  transition: var(--transition-speed)

  @media screen and (max-width: 600px)
    gap: 8px

.header-mobile__lang-item+.header-mobile__lang-item
  padding-left: 24px

.header-mobile__lang-item+.header-mobile__lang-item:before
  position: absolute
  content: ''
  width: 1px
  height: 16px
  top: 50%
  left: 0
  transform: translateY(-50%)
  background-color: var(--primary-color-light-grey-3)

.header-mobile__lang-item:hover,
.header-mobile__lang-item.selected
  color: var(--btn-color-blue)

.mobile-nav__link,
.header-mobile__lang-item
  font-size: 18px
  line-height: 21px
  padding: 20px 0

  @media screen and (max-width: 600px)
    font-size: 16px
    line-height: 18px
    padding: 18px 0

  @media screen and (max-width: 350px)
    font-size: 14px
    line-height: 16px

.icon-blue-check
  width: 14px
  height: 12px
</style>
