<template>
  <div id="contacts" class="main-footer">
    <div class="container--main-page">
      <div class="main-footer__content">
        <div class="main-footer__row">
          <AppLogo class="main-footer__logo" />
          <div class="main-footer__schedule shedule">
            <div class="shedule__title">
              {{ $t('footer.shedule') }}
            </div>
            <div class="shedule__column">
              <div class="shedule__text">
                <div class="shedule__text--bold">
                  {{ $t('footer.support') }}
                </div>
                {{ $t('footer.time_text') }}
                09:00 - 19:00
              </div>
              <div class="shedule__text">
                <div class="shedule__text--bold">
                  {{ $t('footer.service') }}
                </div>
                {{ $t('footer.round_clock') }}
              </div>
            </div>
            <div class="shedule__text shedule__text--wrap">
              <div class="shedule__wrap">
                <div class="shelule__text--small">
                  <div class="shedule__text--bold">
                    {{ $t('footer.office') }}
                  </div>
                  {{ $t('footer.time_text') }}
                  09:00 - 19:00.
                </div>
                <div class="shedule__text--break">
                  {{ $t('footer.break') }}
                  <span>13:00 - 14:00</span>
                </div>
              </div>
            </div>
          </div>
          <div class="main-footer__contacts contacts">
            <div class="contacts__title">
              {{ $t('footer.contacts') }}
            </div>
            <div class="contacts__text">
              <svg-icon name="footer-phone"></svg-icon>
              <a class="contacts__phone-link" href="tel:+380672383282"
                >+38 (067) 238 32 82,</a
              >
              <a class="contacts__phone-link" href="tel:+380632383282"
                >+38 (063) 238 32 82</a
              >
            </div>
            <a href="viber://chat?number=+380672383282" class="contacts__text">
              <svg-icon name="footer-viber"></svg-icon>
              +38 (067) 238 32 82
            </a>
            <a href="mailto:etqueueu@gmail.com" class="contacts__text">
              <svg-icon name="footer-mail"></svg-icon>
              etqueueu@gmail.com
            </a>
            <a
              href="https://goo.gl/maps/VDrhsuPZ94CG1JGA6"
              target="_blank"
              class="contacts__text"
            >
              <svg-icon name="footer-map-point"></svg-icon>
              м. Київ, 03124, бульв. Вацлава Гавела, буд. 8-А
            </a>
          </div>
        </div>
        <div class="main-footer__row anchors">
          <div class="anchor__logo">
            <AppLogo />
          </div>
          <a href="#faq" class="anchors__faq" @click="goToMainPage"
            ><span>F&Q</span></a
          >
          <a href="#how-work" @click="goToMainPage"
            ><span class="anchors__anchor">
              {{ $t('footer.how_sing_up') }}
            </span></a
          >
          <a href="#how-get" @click="goToMainPage"
            ><span class="anchors__anchor">
              {{ $t('main_page.how_book') }}
            </span></a
          >
          <a href="#usefull-info" @click="goToMainPage"
            ><span class="anchors__anchor">
              {{ $t('footer.info') }}
            </span></a
          >
          <a @click="goToTerms"
            ><span class="anchors__anchor">
              {{ $t('footer.terms') }}
            </span></a
          >
          <a class="anchors__policy" @click="goToPrivacy"
            ><span class="anchors__anchor">
              {{ $t('footer.policy') }}
            </span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppLogo from '@/components/Logo/AppLogo.vue';

export default {
  components: {
    AppLogo,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    goToPrivacy() {
      this.$router.push({ name: 'PrivacyPage' });
    },
    goToTerms() {
      this.$router.push({ name: 'TermsPage' });
    },
    goToMainPage() {
      this.$router.push({ name: 'MainPage' });
    },
  },
};
</script>
<style lang="sass">
.main-footer__content
  display: flex
  flex-direction: column
  @media screen and (max-width: 1199px)
    flex-direction: column-reverse

.main-footer__row
  display: flex
  justify-content: space-between
  flex-wrap: wrap

.logo.main-footer__logo
  width: 25%
  @media screen and (max-width: 1199px)
    display: none !important


.main-footer__row:first-child
  margin-bottom: 35px
  margin-top: 35px

.contacts__title,
.shedule__title
  font-size: 24px
  font-weight: 600
  @media screen and (max-width: 450px)
    padding-right: 0
  @media screen and (max-width: 350px)
    font-size: 20px

.shedule,
.contacts
  display: flex
  flex-direction: column
  gap: 16px
  @media screen and (max-width: 1199px)
    padding-right: 30px
  @media screen and (max-width: 450px)
    padding-right: 0

.shedule__column
  display: flex
  flex-direction: column
  gap: 16px
  @media screen and (max-width: 750px)
    flex-direction: row
    gap: 60px

.shedule__wrap
  display: flex
  flex-direction: column
  gap: 4px
  @media screen and (max-width: 750px)
    flex-direction: row !important
    gap: 56px

.shedule__text,
.contacts__text
  display: flex
  gap: 5px
  font-weight: 500
  @media screen and (max-width: 600px)
    font-size: 15px
    line-height: 17px
  @media screen and (max-width: 350px)
    font-size: 12px
    line-height: 14px

.shedule__text--wrap
  flex-wrap: wrap
  @media screen and (max-width: 750px)
    flex-direction: row !important
    gap: 16px
    margin-bottom: 32px

.shelule__text--small
  display: flex
  gap: 5px
  @media screen and (max-width: 1199px)
    flex-direction: column

.shedule__text
  @media screen and (max-width: 1199px)
    flex-direction: column

.shedule__text--break
  span
    @media screen and (max-width: 1199px)
      display: block

    @media screen and (max-width: 650px)
      padding-top: 5px

.contacts__text
  display: flex
  align-items: center
  gap: 8px
  @media screen and (max-width: 650px)
    gap: 4px

.contacts__text svg
  height: 16px
  @media screen and (max-width: 650px)
    height: 12px
.contacts__text:nth-child(odd) svg
  height: 18px
  @media screen and (max-width: 650px)
    height: 14px
.contacts__text:nth-child(4) svg
  height: 12px
  @media screen and (max-width: 650px)
    height: 8px

.shedule__text--bold
  font-weight: 600

.contacts__text,
.contacts__phone-link
  color: var(--primary-color-dark-blue)
  &:hover
    color: var(--btn-color-blue)

.anchors
  display: flex
  align-items: center
  justify-content: space-between
  padding-top: 24px
  padding-bottom: 25px
  border-top: 1px solid var(--primary-color-light-grey-2)
  @media screen and (max-width: 1199px)
    border-top: none
    border-bottom: 1px solid var(--primary-color-light-grey-2)
    column-gap: 35px
    row-gap: 20px
    padding-top: 50px
    padding-bottom: 32px
  @media screen and (max-width: 650px)
    padding-top: 40px
    row-gap: 12px
  @media screen and (max-width: 570px)
    display: grid
    column-gap: 0

  @media screen and (max-width: 350px)
    padding-top: 32px

.anchor__logo
  display: none
  @media screen and (max-width: 1199px)
    display: block
    margin-bottom: 4px
    width: 100%
  @media screen and (max-width: 570px)
    grid-column: 1 / 3

.anchors__faq
  font-size: 24px
  @media screen and (max-width: 600px)
    font-size: 20px
  @media screen and (max-width: 350px)
    font-size: 18px

.anchors__policy
  @media screen and (max-width: 890px)
    flex-grow: 1
  @media screen and (max-width: 570px)
    padding-left: 0
    grid-column: 1 / 2
    grid-row: 4 / 5

.anchors a
  border-bottom: 1px solid transparent
  color: var(--primary-color-dark-blue)

.anchors>a:hover
  border-bottom: 1px solid var(--btn-color-blue-hover)
  color: var(--btn-color-blue-hover)
  cursor: pointer

.anchors__anchor
  @media screen and (max-width: 600px)
    font-size: 14px
    line-height: 19px
  @media screen and (max-width: 350px)
    font-size: 12px
    line-height: 16px
</style>
