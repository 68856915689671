<template>
  <img src="@/assets/images/logo/company-logo.svg" alt="BAZU" />
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
img
  display: block

  @media screen and (max-width: 600px)
    max-width: 70px

  @media screen and (max-width: 350px)
    max-width: 52px
</style>
